import cx from 'classnames';
import { graphql } from 'gatsby';
import React, { useCallback, useState } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import Gallery from 'react-photo-gallery';
import { Sticky, StickyContainer } from 'react-sticky';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

import styles from './gallery.module.scss';

const GalleryPage = ({ location, data }) => {
  const photos = [];
  for (let i = 1; i <= 8; i += 1) {
    const image = data[`image${i}`].childImageSharp.fluid;
    photos.push({
      src: image.src,
      srcSet: image.srcSet,
      width: image.aspectRatio,
      height: 1
    });
  }

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <Layout location={location} className={styles.root}>
      <SEO title="關於我們" />
      <section className={cx(styles.content, styles.desktop)}>
        <StickyContainer>
          <div className={styles.wrapper}>
            <Sticky disableCompensation topOffset={-100} bottomOffset={100}>
              {({ style, isSticky }) => (
                <div
                  className={cx(styles.text, { [styles.sticky]: isSticky })}
                  style={{ position: style.position, top: style.top, left: style.left }}
                >
                  <h1>優越儲存環境</h1>
                  <p>
                    除了優質的服務及獨特的存倉單位設計以外，我們還提供整體舒適優越的環境，讓你在舒適的環境中整理物品。我們在公共使用空間為您準備了各種文具、手機充電站甚至雨傘，以備您的不時之需。在各個流動倉內更設有讓您擺放衣物手袋的位置，以便您能空出雙手好好整理您的寄存物品。除各種尺寸的標準倉外，我們備有多款精心設計的特色倉，務求仔細照顧到您的各種需要。
                  </p>
                </div>
              )}
            </Sticky>
            <div className={styles.gallery}>
              <Gallery photos={photos} onClick={openLightbox} />
            </div>
          </div>
        </StickyContainer>
      </section>
      <section className={cx(styles.content, styles.mobile)}>
        <div className={styles.wrapper}>
          <div className={styles.text}>
            <h1>優越儲存環境</h1>
            <p>
              除了優質的服務及獨特的存倉單位設計以外，我們還提供整體舒適優越的環境，讓你在舒適的環境中整理物品。我們在公共使用空間為您準備了各種文具、手機充電站甚至雨傘，以備您的不時之需。在各個流動倉內更設有讓您擺放衣物手袋的位置，以便您能空出雙手好好整理您的寄存物品。除各種尺寸的標準倉外，我們備有多款精心設計的特色倉，務求仔細照顧到您的各種需要。
            </p>
          </div>
          <div className={styles.gallery}>
            <Gallery photos={photos} onClick={openLightbox} />
          </div>
        </div>
      </section>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </Layout>
  );
};

export default GalleryPage;

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "gallery-D0602902.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1200, quality: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "gallery-D0603063.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1200, quality: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "gallery-D0603103.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image4: file(relativePath: { eq: "gallery-D0603262.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1200, quality: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image5: file(relativePath: { eq: "gallery-D0603356.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1200, quality: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image6: file(relativePath: { eq: "gallery-D0603379.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1200, quality: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image7: file(relativePath: { eq: "gallery-D0602946.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image8: file(relativePath: { eq: "gallery-D0603072.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1200, quality: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
